import { Slideshow } from './module/slideshow'
import { ECHILD } from 'constants';

(function () {
    'use strict'

    const body = document.body

    const sliders = document.querySelectorAll('.m__slider')

    if (sliders) {
        for (let i=0, lng=sliders.length; i<lng; i++) {

            let slider = new Slideshow(sliders[i])
            slider.init()
        }
    }

    const displayForm = el => {

        if (document.querySelector('.m__fixedForm__trigger').classList.contains('trigger'))
            return 
        
        document.querySelector('.m__fixedForm__trigger').classList.add('trigger')
        document.querySelector('.m__fixedForm').classList.add('trigger')
        body.classList.add('overflow')
    }

    const closeForm = el => {
        document.querySelector('.m__fixedForm__trigger').classList.remove('trigger')
        document.querySelector('.m__fixedForm').classList.remove('trigger')
        body.classList.toggle('overflow')
    }

    const scrolled = () => {

        let el = document.querySelector('.s__fixedForm__trigger')

        if (!el) return

        if (window.scrollY > 0 && !el.classList.contains('scrolled'))
            el.classList.add('scrolled')

        if (window.scrollY === 0 && el.classList.contains('scrolled'))
            el.classList.remove('scrolled')
    }
    scrolled()

    body.addEventListener('click', e => {

        let el = e.target

        if (el.classList.contains('m__fixedForm__trigger') || (el.classList.contains('m__cta') && el.type !== 'submit'))
            return displayForm(el)

        if (el.classList.contains('m__fixedForm__closer'))
            return closeForm()
    })

    window.addEventListener('scroll', () => {
        scrolled()
    })

})()